const t = new URLSearchParams(location.search.slice(1).replace(/\+/g, "%20")).get("t");

// We respect the redirect target only if it is for the same hostname and isn't the login page.
const tAnchor = document.createElement("a");
tAnchor.href = t || "/";
// Some cross-browser compatibility issues: IE will set an empty hostname for an anchor that doesn't
// specify one (e.g. a relative or absolute path).  Some versions will also omit the leading slash
// in the pathname.
export const target =
    t
    && (!tAnchor.hostname || tAnchor.hostname === window.location.hostname)
    && tAnchor.pathname !== "/login.do"
    && tAnchor.pathname !== "login.do"
        ? tAnchor.href + location.hash
        : "/";

export function isDefault() {
    return target === "/";
}

export function go() {
    location.replace(target);
}
