import dojo_cookie = require("dojo/cookie");
import SamlPanel = require("Everlaw/AdminTabs/SamlPanel");

enum Cookie {
    RETURNING_USER = "RETURNING_USER",
    LOGIN_METHOD = "LOGIN",
    IDENTITY_PROVIDER = "IDP",
    SSO_ORG = "SSO_ORG",
    ADVERTISE_OPT_OUT = "ADVERTISE_OPT_OUT",
}

enum Auth {
    PASSWORD = "pw",
    SSO = "sso",
}
const VERSION_NUMBER: string = JSP_PARAMS.Versioning.assetVersion.match(/[^.]*/i)?.[0] || "";

export function isAdvertiseOptOut() {
    return dojo_cookie(Cookie.ADVERTISE_OPT_OUT + VERSION_NUMBER) !== undefined;
}

export function setAdvertiseOptOut() {
    return dojo_cookie(Cookie.ADVERTISE_OPT_OUT + VERSION_NUMBER, "true");
}

export function isLoggedInWithSso() {
    return dojo_cookie(Cookie.LOGIN_METHOD) === Auth.SSO;
}

export function isLoggedInWithPassword() {
    return dojo_cookie(Cookie.LOGIN_METHOD) === Auth.PASSWORD;
}

export function setSsoLogin(entityId: string) {
    dojo_cookie(Cookie.LOGIN_METHOD, Auth.SSO, { path: "/" });
    dojo_cookie(Cookie.IDENTITY_PROVIDER, entityId, { path: "/" });
}

export function setPasswordLogin() {
    dojo_cookie(Cookie.LOGIN_METHOD, Auth.PASSWORD, { path: "/" });
}

export function isOrgIDPSet(): boolean {
    return !!dojo_cookie(Cookie.SSO_ORG) && !!dojo_cookie(Cookie.IDENTITY_PROVIDER);
}

/**
 * Constructs a Single Sign-On (SSO) URL for authentication using SAML 2.0.
 *
 * @param {string} ssoOrgId - The SSO organization ID
 * @param {string} [redirectTarget] - Optional. A not encoded URL to redirect the user after
 *                                    successful authentication.
 * @returns {string} - The SSO URL for initiating the authentication process.
 */
export function getSsoUrl(ssoOrgId: string, redirectTarget?: string): string {
    let url =
        "/saml2/authenticate/"
        + SamlPanel.base64UrlSafeEncode(dojo_cookie(Cookie.IDENTITY_PROVIDER));
    const relayState = encodeURIComponent(
        "orgId=" + ssoOrgId + (redirectTarget ? "&t=" + encodeURIComponent(redirectTarget) : ""),
    );
    url += "?RelayState=" + relayState;
    return url;
}

export function getReturningUser() {
    return dojo_cookie(Cookie.RETURNING_USER);
}

export function getSsoOrg(): string {
    return dojo_cookie(Cookie.SSO_ORG);
}

export function clear() {
    deleteCookie(Cookie.RETURNING_USER);
    deleteCookie(Cookie.IDENTITY_PROVIDER);
    deleteCookie(Cookie.LOGIN_METHOD);
    deleteCookie(Cookie.SSO_ORG);
}

function deleteCookie(name: string) {
    dojo_cookie(name, undefined, { expires: -1, path: "/" });
}
